var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a21f512eeea4011b11c1cc6196e0761efc21dae3"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

import { ignoreErrors, denyUrls } from "./sentry.ignore";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (SENTRY_DSN) {
  Sentry.init({
    beforeSend: event => {
      try {
        // Skip events from anonymous sources (like scripts set in Google Tag Manager)
        const exceptions = event.exception?.values;
        if (exceptions && exceptions.length > 0) {
          const { stacktrace } = exceptions[0];
          if (stacktrace && stacktrace.frames && stacktrace.frames.length > 0) {
            return stacktrace.frames[0].filename === "<anonymous>"
              ? null
              : event;
          }
        }
      } catch {
        // Otherwise send event
      }
      return event;
    },
    dsn: SENTRY_DSN,
    enabled: true,
    environment: process.env.ENVIRONMENT,
    ignoreErrors,
    denyUrls,
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
